<template>
  <ion-page id="scan-content">
    <ion-content :fullscreen="true">
      <topbar class="noBackground" :bigtitle="false" :showClose="true" :noboder="true"></topbar>

      <div class="overlay-element" v-if="show"></div>
      <div class="box" v-if="show">
        <div class="box-content">
          <p class="text Medium">Hold Barcode here<br />It will scan automatically.</p>
          <img class="box-img img0" src="@/assets/path-2@3x.png" />
          <img class="box-img img1" src="@/assets/path-2@3x.png" />
          <img class="box-img img2" src="@/assets/path-2@3x.png" />
          <img class="box-img img3" src="@/assets/path-2@3x.png" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";

import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
export default {
  name: "scanCode",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    backEvent() {
      this.$router.go(-1);
    },
    async scanEvent() {
      await this.checkPermission();
      BarcodeScanner.hideBackground();
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        if (this.$route.query.type) {
          this.$store.state.searchValue = result.content;
        } else {
          this.$store.state.userInfo.physicalCardNumber = result.content;
          this.$store.state.form.userInfo.physicalCardNumber = result.content;
        }
      }
      this.backEvent();
    },
    async stopScan() {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      // this.backEvent();
    },
    async checkPermission() {
      const status = await BarcodeScanner.checkPermission({ force: true });

      console.log(status.granted);
      if (status.granted) {
        return true;
      } else if (status.denied) {
        BarcodeScanner.openAppSettings();
      }

      return false;
    },
  },
  ionViewWillLeave() {
    this.stopScan();
  },
  ionViewDidEnter() {
    // setTimeout(() => {
    this.show = true;
    this.scanEvent();
    // });
    this.$store.state.exitApp = false;
    this.sendGAPageView("Scan Code");
  },
};
</script>
<style scoped lang="scss">
ion-content {
  --background: #00000000;
}
.box {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  .box-content {
    width: 100%;
    text-align: center;
    .box-img {
      width: 30px;
    }
    .img0 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img1 {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }
    .img2 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-90deg);
    }
    .img3 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }
    .text {
      color: #fff;
      text-shadow: 0 0 3px rgba($color: #fff, $alpha: 0.3);
      padding: 15% 9%;
    }
  }
  .scanTips {
    font-size: 14px;
    color: white;
    text-align: center;
    position: absolute;
    bottom: -85px;
    left: 0;
    right: 0;
  }
}
.overlay-element {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.5);

  -webkit-clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 40%, 100% 40%, 100% 60%, 0% 60%, 0% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 40%, 100% 40%, 100% 60%, 0% 60%, 0% 100%, 100% 100%, 100% 0%);
}

.laser {
  width: 100%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning1 2s infinite;
  animation: scanning1 2s infinite;
  display: none;
}
@-webkit-keyframes scanning1 {
  50% {
    -webkit-transform: translateY(90px);
    transform: translateY(90px);
  }
}
@keyframes scanning1 {
  50% {
    -webkit-transform: translateY(90px);
    transform: translateY(90px);
  }
}
</style>
